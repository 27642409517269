<template>
  <div class="page-content">
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9">
      <div class="card shadow">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Daftar Lowongan Kerja</h3>
            <div class="ml-auto">
              <router-link
                class="mr-2"
                :to="{ name: 'Tambah Lowongan Kerja' }"
              >
                <base-button
                  type="primary"
                  @click="goToAddPage"
                >Tambah</base-button>
              </router-link>
              <base-button
                type="danger"
                :disabled="deleteJobVacanciesPromised.state.loading"
                @click="deleteSelectedRow"
              >Hapus</base-button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <el-table
            ref="listJobVacanciesTable"
            :data="jobVacancy.data"
            style="width: 100%"
            v-loading="getJobVacanciesPromised.state.loading"
            @selection-change="selectTableRow"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <el-table-column
              property="title"
              label="Judul"
              min-width="150"
            />
            <el-table-column
              property="open_date"
              label="Tanggal Buka"
              min-width="150"
              align="center"
            />
            <el-table-column
              property="close_date"
              label="Tanggal Tutup"
              min-width="150"
              align="center"
            />
            <el-table-column
              property="is_active"
              label="Status"
              align="center"
              min-width="150"
            >
              <template #default="scope">
                <base-button
                  size="sm"
                  :type="scope.row.is_active === true ? 'success' : 'danger'"
                  disabled
                >{{ scope.row.is_active ? 'AKTIF' : 'TIDAK AKTIF' }}</base-button>
              </template>
            </el-table-column>

            <el-table-column
              label="Aksi"
              width="175"
              align="center"
            >
              <template #default="scope">
                <el-button
                  @click="goToDetailPage(scope)"
                  type="primary"
                  size="small"
                  icon="el-icon-view"
                  round
                ></el-button>
                <el-button
                  @click="goToEditPage(scope)"
                  type="primary"
                  size="small"
                  icon="el-icon-edit"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  round
                  @click="selectTableRow([{ ...scope.row }], true)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="text-center my-2">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="jobVacancy.total"
              :page-size="jobVacancy.per_page"
              @current-change="changePageTable"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { useMeta } from "vue-meta";
import { useRoute, useRouter } from "vue-router";
import { StatusCodes } from 'http-status-codes';

import usePageMeta from "@/composables/usePageMeta";
import useAxios from '@/composables/useAxios'
import useSwal from '@/composables/useSwal'

import jobVacancyService from '@/services/JobVacancy'

export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const Swal = useSwal()

    useMeta(usePageMeta(route).pageMeta)

    const state = reactive({
      jobVacancy: {
        data: [],
        total: 0,
        per_page: 10,
        page: 1,
        selectedId: []
      },
    })

    // TABLE ACTION
    const goToDetailPage = (scope) => {
      router.push({
        name: 'Detail Lowongan Kerja',
        params: {
          id: scope.row.id
        }
      })
    }
    const goToAddPage = () => {
      router.push({
        name: 'Tambah Lowongan Kerja'
      })
    }
    const goToEditPage = (scope) => {
      router.push({
        name: 'Ubah Lowongan Kerja',
        params: {
          id: scope.row.id
        }
      })
    }

    const listJobVacanciesTable = ref()

    const selectTableRow = async (val, deleteImmediately = false) => {
      if (deleteImmediately === true) {
        listJobVacanciesTable.value.clearSelection()
      }

      state.jobVacancy.selectedId = val.map((value) => value.id)

      if (deleteImmediately === true) {
        await deleteSelectedRow()
        state.jobVacancy.selectedId = []
      }
    }

    const changePageTable = async (currentPage) => {
      state.jobVacancy.page = currentPage
      await getJobVacancies()
    }

    // GET SECTION
    const getJobVacanciesPromised = useAxios((payload = {}) => {
      return jobVacancyService.superadmin.getJobVacancies(payload)
    })

    const getJobVacancies = async () => {
      await getJobVacanciesPromised.createPromise({
        params: {
          paginate: true,
          per_page: state.jobVacancy.per_page,
          page: state.jobVacancy.page
        }
      })

      if (getJobVacanciesPromised.state.response && getJobVacanciesPromised.state.statusCode === StatusCodes.OK) {
        const data = getJobVacanciesPromised.state.data

        Object.assign(state.jobVacancy, {
          data: data.data.data,
          total: data.data.total,
          per_page: Number(data.data.per_page),
          page: data.data.current_page
        })
      } else if (getJobVacanciesPromised.state.error) {
        Swal.fire({
          icon: 'error',
          title: 'Gagal mendapatkan lowongan kerja',
          text: getJobVacanciesPromised.state.errorMessage,
        })
      }
    }

    // DELETE SECTION
    const deleteSelectedRow = async () => {
      if (!state.jobVacancy.selectedId.length) {
        Swal.fire({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 2000,
          icon: 'info',
          title: 'Silakan pilih lowongan kerja yang akan dihapus',
        })
        return
      }

      const continueDelete = await new Promise((resolve, reject) => {
        Swal.fire({
          icon: "warning",
          title: "Apa kamu yakin?",
          text: "Lowongan kerja yang dihapus tidak dapat dikembalikan",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Hapus",
          cancelButtonText: "Batal",
        }).then(result => {
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })

      if (continueDelete.value) {
        await deleteJobVacancies()
        if (
          deleteJobVacanciesPromised.state.response &&
          getJobVacanciesPromised.state.statusCode === StatusCodes.OK
        ) {
          state.jobVacancy.selectedId = []
          await getJobVacancies()
        }
      }
    }

    const deleteJobVacanciesPromised = useAxios((payload = {}) => {
      return jobVacancyService.superadmin.deleteJobVacancies(payload)
    }, {
      withIndicator: true
    })
    const deleteJobVacancies = async () => {
      await deleteJobVacanciesPromised.createPromise({
        data: {
          id: state.jobVacancy.selectedId
        }
      })

      if (deleteJobVacanciesPromised.state.response && getJobVacanciesPromised.state.statusCode === StatusCodes.OK) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil menghapus lowongan kerja',
        })
      } else if (deleteJobVacanciesPromised.state.error) {
        Swal.fire({
          icon: 'error',
          title: 'Gagal menghapus lowongan kerja',
          text: getJobVacanciesPromised.state.errorMessage,
        })
      }
    }

    onMounted(async () => {
      await getJobVacancies()
    })

    return {
      ...toRefs(state),
      listJobVacanciesTable,
      goToDetailPage,
      goToAddPage,
      goToEditPage,
      selectTableRow,
      deleteSelectedRow,
      changePageTable,
      getJobVacanciesPromised,
      getJobVacancies,
      deleteJobVacanciesPromised,
      deleteJobVacancies
    };
  },
});
</script>