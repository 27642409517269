export const disableDateBeforeNow = (time) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return time.getTime() < yesterday.getTime()
}

export const disableDateAfterNow = (time) => {
  return time.getTime() > Date.now()
}

export const disabledByDateStart = (time, dateStart) => {
  const startDate = new Date(dateStart)
  return (
    time.getTime() < startDate.setDate(startDate.getDate() - 1)
  )
}