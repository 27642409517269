<template>
  <div class="page-content">
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9">
      <div class="card shadow">
        <div class="card-header bg-transparent">
          <h3 class="mb-0">Ubah Lowongan Kerja</h3>
        </div>
        <div class="card-body">
          <el-form
            label-position="top"
            label-width="100px"
            ref="formEditJobVacancyRef"
            :model="formEditJobVacancy"
            :rules="formEditJobVacancyRules"
            @submit.prevent="submitFormEditJobVacancy"
          >
            <div class="row">
              <div class="col-md-6">
                <el-form-item
                  label="Judul"
                  prop="title"
                >
                  <el-input v-model="formEditJobVacancy.title"></el-input>
                </el-form-item>
                <el-form-item
                  label="Deskripsi Singkat"
                  prop="short_description"
                >
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 5, maxRows: 6}"
                    v-model="formEditJobVacancy.short_description"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="Kategori"
                  prop="category"
                  style="margin-top: 22px;"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditJobVacancy.category"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="&nbsp;"
                    :remote-method="searchCategories"
                    :loading="getCategoriesPromised.state.loading"
                  >
                    <el-option
                      v-for="category in categories"
                      :key="category.id"
                      :label="category.name.charAt(0).toUpperCase() + category.name.slice(1)"
                      :value="category.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-md-6">
                <el-form-item
                  label="Tanggal Buka"
                  prop="open_date"
                >
                  <el-date-picker
                    v-model="formEditJobVacancy.open_date"
                    type="date"
                    class="w-100"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disableDateBeforeNow"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="Tanggal Tutup"
                  prop="close_date"
                >
                  <el-date-picker
                    v-model="formEditJobVacancy.close_date"
                    type="date"
                    class="w-100"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disableDateBeforeNow"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="Kata Kunci"
                  prop="keywords"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditJobVacancy.keywords"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="&nbsp;"
                  >
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="Status"
                  prop="is_active"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditJobVacancy.is_active"
                    placeholder="&nbsp;"
                  >
                    <el-option
                      label="AKTIF"
                      :value="true"
                    >
                    </el-option>
                    <el-option
                      label="TIDAK AKTIF"
                      :value="false"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <el-form-item
              label="Deskripsi"
              prop="description"
            >
              <tiptap-classic
                class="tiptap-description"
                v-model="formEditJobVacancy.description"
              />
            </el-form-item>
            <el-form-item
              label="Tentang Paninti"
              prop="about_paninti"
            >
              <tiptap-classic
                class="tiptap-about-paninti"
                v-model="formEditJobVacancy.about_paninti"
              />
            </el-form-item>
            <el-form-item
              label="Tentang Team"
              prop="about_team"
            >
              <tiptap-classic
                class="tiptap-about-team"
                v-model="formEditJobVacancy.about_team"
              />
            </el-form-item>

            <div class="mt-4">
              <base-button
                native-type="submit"
                :disabled="updateJobVacancyPromised.state.loading"
                type="primary"
              >{{ !updateJobVacancyPromised.state.loading ? 'Simpan Perubahan' : 'Simpan Perubahan...' }}</base-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "vue";

import TiptapClassic from '@/components/Tiptap/ClassicEditor.vue'
import { useRoute, useRouter } from "vue-router";
import { useMeta } from "vue-meta";
import usePageMeta from "@/composables/usePageMeta";
import useAxios from "@/composables/useAxios";
import { disableDateBeforeNow } from '@/composables/useElementDatePicker'

import categoryService from "@/services/Category"
import useSwal from "@/composables/useSwal";

import jobVacancyService from '@/services/JobVacancy'

export default defineComponent({
  components: {
    TiptapClassic
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const Swal = useSwal()

    useMeta(usePageMeta(route).pageMeta)

    const formEditJobVacancyRef = ref()
    const initFormEditJobVacancy = () => {
      return {
        title: '',
        short_description: '',
        description: '',
        about_paninti: '',
        about_team: '',
        open_date: null,
        close_date: null,
        category: '',
        keywords: [],
        is_active: false
      }
    }

    const state = reactive({
      formEditJobVacancy: initFormEditJobVacancy(),
      formEditJobVacancyRules: {
        title: [
          { required: true, message: 'Silakan masukkan judul lowongan kerja', trigger: ['change', 'blur'] },
          { min: 8, message: 'Minimal panjang harus 8 karakter', trigger: ['change', 'blur'] }
        ],
        short_description: [
          { required: true, message: 'Silakan masukkan deskripsi singkat lowongan kerja', trigger: ['change', 'blur'] }
        ],
        description: [
          { required: true, message: 'Silakan masukkan deskripsi lowongan kerja', trigger: ['change', 'blur'] }
        ],
        about_paninti: [
          { required: true, message: 'Silakan masukkan tentang paninti lowongan kerja', trigger: ['change', 'blur'] }
        ],
        about_team: [
          { required: true, message: 'Silakan masukkan tentang tim', trigger: ['change', 'blur'] }
        ],
        open_date: [
          { required: true, message: 'Silakan pilih tanggal buka lowongan kerja', trigger: ['change', 'blur'] },
        ],
        close_date: [
          { required: true, message: 'Silakan masukkan tanggal tutup lowongan kerja', trigger: ['change', 'blur'] }
        ],
        category: [
          { required: true, message: 'Silakan masukkan kategori lowongan kerja', trigger: ['change', 'blur'] }
        ],
        keywords: [
          { required: true, message: 'Silakan masukkan kata kunci lowongan kerja', trigger: ['change', 'blur'] }
        ],
        is_active: [
          { required: true, message: 'Silakan masukkan status lowongan kerja', trigger: ['change', 'blur'] },
        ]
      },
      categories: []
    })
    const getCategoryPayload = ref({})

    const paramId = computed(() => route.params.id)

    const searchCategories = async (query) => {
      if (query === '') {
        return
      }

      getCategoryPayload.value = {
        params: {
          search: query
        }
      }

      await getCategories()
    }

    const getCategoriesPromised = useAxios((payload) => {
      return categoryService.superadmin.getCategories(payload)
    })

    const getCategories = async () => {
      await getCategoriesPromised.createPromise(getCategoryPayload.value)

      if (getCategoriesPromised.state.response) {
        state.categories = getCategoriesPromised.state.data
      } else if (getCategoriesPromised.state.error) {
        Swal.fire({
          icon: 'error',
          title: 'Gagal mendapatkan kategori',
          text: getCategoriesPromised.state.errorMessage,
        })
      }

      getCategoryPayload.value = {}
    }

    const getJobVacancyPromised = useAxios((id, payload = {}) => {
      return jobVacancyService.superadmin.getJobVacancy(id, payload)
    })

    const getJobVacancy = async () => {
      await getJobVacancyPromised.createPromise(paramId.value)

      if (getJobVacancyPromised.state.response) {
        state.formEditJobVacancy = {
          ...getJobVacancyPromised.state.data,
          category: getJobVacancyPromised.state.data.categories.id,
          keywords: getJobVacancyPromised.state.data.keywords.map(keyword => keyword.name)
        }
      } else if (getJobVacancyPromised.state.error) {
        const continueNotFound = await new Promise((resolve, reject) => {
          Swal.fire({
            icon: 'error',
            title: 'Gagal mendapatkan lowongan kerja',
            text: getJobVacancyPromised.state.errorMessage,
          }).then(result => {
            resolve(result)
          }).catch(error => {
            reject(error)
          })
        })

        if (continueNotFound.value) {
          router.push({
            name: 'Kelola Lowongan Kerja'
          })
        }
      }

      getCategoryPayload.value = {}
    }

    const updateJobVacancyPromised = useAxios((id, payload = {}) => {
      return jobVacancyService.superadmin.updateJobVacancy(id, payload)
    }, {
      withIndicator: true
    })
    const submitFormEditJobVacancy = async () => {
      formEditJobVacancyRef.value.validate(async (valid) => {
        if (!valid) {
          return false
        }

        await updateJobVacancyPromised.createPromise(paramId.value, {
          data: state.formEditJobVacancy
        })

        if (updateJobVacancyPromised.state.response) {
          state.formEditJobVacancy = initFormEditJobVacancy()
          formEditJobVacancyRef.value.resetFields()

          Swal.fire({
            icon: 'success',
            title: 'Berhasil memperbarui lowongan kerja',
            willClose: () => {
              router.push({
                name: 'Kelola Lowongan Kerja'
              })
            }
          })
        } else if (updateJobVacancyPromised.state.error) {
          Swal.fire({
            icon: 'error',
            title: 'Gagal memperbarui lowongan kerja',
            text: updateJobVacancyPromised.state.errorMessage,
          })
        }
      })
    }

    onMounted(async () => {
      await getJobVacancy()
      await getCategories()
    })

    return {
      ...toRefs(state),
      formEditJobVacancyRef,
      disableDateBeforeNow,
      searchCategories,
      getCategoriesPromised,
      getCategories,
      updateJobVacancyPromised,
      submitFormEditJobVacancy
    }
  }
})
</script>

<style lang="scss" scoped>
.tiptap-description,
.tiptap-about-paninti,
.tiptap-about-team {
  min-height: 250px;
}
</style>

<style lang="scss">
.el-form-item {
  margin-bottom: 10px;
  .el-form-item__label {
    margin-bottom: 0;
    padding: 0;
  }
}
</style>