<template>
  <div class="page-content">
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>

    <div
      class="container-fluid mt--9"
      v-if="jobVacancy.id"
    >
      <div class="card shadow">
        <div class="card-header bg-transparent">
          <h3 class="mb-0">Informasi Dasar</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <table class="border-0">
                <tbody>
                  <tr>
                    <td>Judul</td>
                    <td class="px-2"> : </td>
                    <td>{{ jobVacancy.title }}</td>
                  </tr>
                  <tr>
                    <td>Kategori</td>
                    <td class="px-2"> : </td>
                    <td>{{ jobVacancy.categories.name }}</td>
                  </tr>
                  <tr>
                    <td>Deskripsi Singkat</td>
                    <td class="px-2"> : </td>
                    <td>{{ jobVacancy.short_description }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6">
              <table class="border-0">
                <tbody>
                  <tr>
                    <td>Tanggal Buka</td>
                    <td class="px-2"> : </td>
                    <td>{{ jobVacancy.open_date }}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Tutup</td>
                    <td class="px-2"> : </td>
                    <td>{{ jobVacancy.close_date }}</td>
                  </tr>
                  <tr>
                    <td>Kata Kunci</td>
                    <td class="px-2"> : </td>
                    <td>{{ jobVacancy.keywords.map(keyword => keyword.name).join(',') }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td class="px-2"> : </td>
                    <td>{{ jobVacancy.is_active === true ? 'AKTIF' : 'TIDAK AKTIF' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow mt-3">
        <div class="card-header bg-transparent">
          <h3 class="mb-0">Deskripsi</h3>
        </div>
        <div class="card-body">
          <div v-html="jobVacancy.description" />
        </div>
      </div>

      <div class="card shadow mt-3">
        <div class="card-header bg-transparent">
          <h3 class="mb-0">Tentang Paninti</h3>
        </div>
        <div class="card-body">
          <div v-html="jobVacancy.about_paninti" />
        </div>
      </div>

      <div class="card shadow mt-3">
        <div class="card-header bg-transparent">
          <h3 class="mb-0">Tentang Tim</h3>
        </div>
        <div class="card-body">
          <div v-html="jobVacancy.about_team" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import useAxios from "@/composables/useAxios";
import useSwal from "@/composables/useSwal";

import jobVacancyService from '@/services/JobVacancy'

export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const Swal = useSwal()

    const state = reactive({
      jobVacancy: {}
    })

    const paramId = computed(() => route.params.id)
    const getJobVacancyPromised = useAxios((id, payload = {}) => {
      return jobVacancyService.superadmin.getJobVacancy(id, payload)
    })

    const getJobVacancy = async () => {
      await getJobVacancyPromised.createPromise(paramId.value)

      if (getJobVacancyPromised.state.response) {
        state.jobVacancy = getJobVacancyPromised.state.data
      } else if (getJobVacancyPromised.state.error) {
        await new Promise((resolve, reject) => {
          Swal.fire({
            icon: 'error',
            title: 'Gagal mendapatkan lowongan kerja',
            text: getJobVacancyPromised.state.errorMessage,
            willClose: () => {
              router.push({
                name: 'Kelola Lowongan Kerja'
              })
            },
          }).then(result => {
            resolve(result)
          }).catch(error => {
            reject(error)
          })
        })
      }
    }

    onMounted(async () => {
      await getJobVacancy()
    })

    return {
      ...toRefs(state),
      getJobVacancy
    }
  }
})
</script>